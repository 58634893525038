// home.scss

@import "ol_popup";

$map-border-radius: 8px;
$map-font-size: 1.5rem;

// TODO: ADD POINTER: CURSOR TO CLICKABLE REGIONS

// make map fixed height
.map {
    height: 558px;
    .ol-attribution {
        position: absolute;
        right: 0;
        bottom: 0;
        left: unset;
        border-radius: $map-border-radius / 4 ;
        padding: 0 ($map-border-radius / 2) 0 ($map-border-radius / 2);
        background-color: rgba(255, 255, 255, 0.6);
        &:hover {
            background-color: rgba(255, 255, 255, 0.6);
        }
        height: auto;
        & > ul {
            font-size: $map-font-size / 2;
            list-style: none;
            margin: 0;
            padding: 0;
            & > li {
                text-align: right;
                padding: 0 2px 0 2px;
                display: inline;
            }
        }
        & > button {
            display: none;
        }
    }
}

// show overlay button on map
.map__overlay__button {
    position: absolute;
    padding: 10px;
    display: block;
    left: 55px;
    top: 480px;
    z-index: 3;

    // @media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
    @media only screen and (max-width: 667px) {
        text-align: center;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 90%;
    }
}

.ol-control, .ol-scale-line {
    //display: none;
    //position: absolute;
}


// required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/close";

@import "modal.scss";




