// required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
.modal {
    padding-right: 0px;

    .modal-dialog {
        max-width: 70%;
        @media only screen and (max-width: 991px) {
            max-width: 100%;
        }
    }

    .modal-body {
        font-size: 1.4rem;
        .close {
            font-size: 4rem;
        }
    }

    .noclick {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal-footer {
        .my-btn-accept {
            color: #fff;
        }
    }
}