// _slider.scss

@import "../../../../node_modules/slick-carousel/slick/slick";
@import "../../../../node_modules/slick-carousel/slick/slick-theme";
@import "button";

// TODO: GET VARS FROM _VARIABLES
$slider-font-size: 1rem;
$slider-bg-color: #2c5c00;
$slider-font-family: BlinkMacSystemFont, 'PT Sans', sans-serif !default;

.slider {
    position: relative;
    background-color: $slider-bg-color;

    &__container {
        padding: 1.5rem 3.5rem 2.5rem; // TODO: get from btstrp?
        overflow: hidden; // hide vertical images that create large space before slider init
        height: 330px; // cause of different slider sizes normal and maximized
    }
    &__title {
        font-size: 1.5rem;
        font-weight: 200;
        color: #fff;
        text-align: left;
        text-transform: uppercase;
        padding-left: 0.5rem;
        padding-bottom: 0.5rem;
    }
    &__text {
        font-size: 1rem;
        color: #fff;
    }
    &__content {
        position: relative;
        display: block;
        box-sizing: border-box;
        user-select: none;
        touch-action: pan-y;
        // overflow: auto;
        // height: 200px;
    }
    &__slide {
        margin: 0 0.5rem 0 0;
    }
    &__slide__text {
        font-size: 1.2rem;
        height: 3rem;
        text-align: center;
        padding-top: 10px;
    }
    &__button {
        @extend .button;
        @extend .button-primary;

        text-align: center;
        margin-right: auto;
        display: block;
        margin-left: auto;
        width: 250px;

        &-link {
            color: #000;
            &:hover {
                color: #000;
            }
        }
    }
    &-large {
        // adjustments for max slider on mit-dem-rad-entdecken
        min-height: 550px; // cause of different slider sizes normal and maximized
    }
}

.slick__prev__next-before-base {
    font-size: 3rem;
    font-family: $slider-font-family !important;
    line-height: 0 !important;
    color: white;
    opacity: .75;
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
}
.slick__prev__next-base{
    z-index: 3;
    display: block;
    border: 0;
 }

.slick {
    &-slide {
        color: white;
        margin: 0 10px;
        font-size: 30px;
        text-align: center;

        img {
            width: 100%;
        }
    }
    &-dots {
        bottom: -30px;
    }
    &-prev {
        @extend .slick__prev__next-base;
        left: -55px !important;
        &:before {
            @extend .slick__prev__next-before-base;
        }
    }
    &-next {
        @extend .slick__prev__next-base;
        right: -30px !important;

        &:before {
            @extend .slick__prev__next-before-base;
        }
    }
    &-list {
        transform: translate3d(0, 0, 0);
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;
    }
    &-track {
        opacity: 1;
        width: 6348px;
        transform: translate3d(-828px, 0px, 0px);
    }
}
