// popup ol style
.ol-popup {
    position: absolute;
    background-color: white;
    padding: 0;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 100px;
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
}

.ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.ol-popup-closer {
    text-decoration: none;
    float: right;
    cursor: pointer;
}

.ol-popup-closer:after {
    content: "✖";
}

// modifications on popup
.o-image-wrap {
    text-align: center;
}

.o-image {
    width: 230px;
    height: auto;
}

.o-teaser {
    font-size: 14px;
}

.o-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 10px 0 10px 0;
}

.ol-popup .region-info {
    min-width: 284px;
    padding: 16px;
}

.ol-popup .poi-popup {
    min-width: 240px;
    padding: 6px;

    & p {
        margin-bottom: 0;
    }

    & .o-title {
        padding: 2px 0 2px 0;
    }

    & .o-site {
        white-space: nowrap;
    }
}

.ol-popup .routing-menu {
    min-width: 100px;
    padding: 6px;

    .point-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
    }

    & .point-item {
        font-size: 14px;
        margin-bottom: 0;
        padding: 0 8px 0 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    & .menu-item {
        cursor: pointer;
    }

    & .point-title.point-item.menu-item {
        padding-left: 0;
    }

    & .menu-item:hover {
        text-decoration: underline;
    }

    & .menu-item:last-child {
        font-size: 14px;
        margin-bottom: 0;
        text-align: center;
    }
}

.ol-popup .ol-popup-content.tab-content {
    min-width: 300px;
    & > .tab-title {
        background-color: #a6ce17;
        & > [data-tab] {
            cursor: pointer;
            padding: 4px 8px 6px 8px;
            display: inline-block;
            &.activated {
                cursor: default;
                background-color: #fff;
            }
        }
    }
    & > .tab-content {
        padding: 4px 8px 4px 8px;
        max-height: 200px;
        overflow-y: scroll;
        & > div {
            display: block;
            p {
                margin: 0;
            }
            &.hidden {
                display: none;
            }
        }
    }
}
