// _button.scss

button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

$button-font-family: 'PT Sans', sans-serif;
$button-font-size: 1rem;
$button-font-weight-normal: 400;
$button-font-weight-light: 300;

$button-color: #000;
$button-color-primary: #A6CE17;
$button-color-secondary: #ccc;
$button-color-background: #ddd;
$button-color-more: #e6e6e6;

$button-border-radius: 10px;
$button-border-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);

.buttons {
    margin: 10px;
    padding: 10px;
}

// Block__Element–Modifier
// B
.button {
    font-family: $button-font-family;
    font-size: $button-font-size;
    font-weight: $button-font-weight-normal;
    color: $button-color;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    display: inline-block;
    border-radius: $button-border-radius;

    &:hover {
        color: $button-color;
        text-decoration: underline;
    }

    // E
    &__link {
        color: $button-color;
        text-decoration: none;

        &:hover, &:focus, &:active, &:visited {
            color: $button-color;
            text-decoration: underline;
        }
    }

    &__download {
        width: 80px;
        height: 80px;
        font-size: 2rem;
        text-transform: uppercase;
        color: $button-color;
        background-color: $button-color-primary;

        &__text {
            background-color: $button-color-background;
        }
    }

    &__more {
        height: 30px;
        border-radius: 0;
        background-color: $button-color-more;
    }

    // M
    &-primary {
        background-color: $button-color-primary;
    }

    &-secondary {
        background-color: $button-color-secondary;
    }

    &-angular {
        border-radius: 0;
    }
}
